import React from "react";

class ErrorField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="error">
                {this.props.error}
            </div>
        );
    }
}

export default ErrorField;
