import React from "react";

class PasswordField extends React.Component {
    confirm;

    constructor(props) {
        super(props);

        this.confirm = props.confirm ? props.confirm : false;
    }

    render() {
        return (
            <div>
                <label>
                    {this.confirm ? 'Confirm password:' : 'Password:'}
                    <input type="password" value={this.props.value} onChange={this.props.onChange} />
                </label>
            </div>
        )
    }
}

export default PasswordField;
