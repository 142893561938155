const DEFAULT_HOST = "https://api.neufville.family"

export function apiStrings() {
    return fetch(`${DEFAULT_HOST}/strings`).then(res => res.json());
}

export function apiCreateUser(email, fullname, password) {
    return fetch(`${DEFAULT_HOST}/create-user`, {
        method: 'POST',
        body: JSON.stringify({
            email,
            fullname,
            password,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export function apiConfirmUser(clientId, username, confirmationCode) {
    return fetch(`${DEFAULT_HOST}/confirm-user`, {
        method: 'POST',
        body: JSON.stringify({
            clientId,
            username,
            confirmationCode,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}