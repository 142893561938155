import React from "react";
import { apiConfirmUser } from "../api/api";
import LoginForm from "../components/forms/LoginForm";

class ConfirmUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmText: "Attempting account confirmation...",
            showLogin: false,
        };

        
        const location = window.location;
        const searchParams = new URLSearchParams(location.search);
        const clientId = searchParams.get('client_id');
        const username = searchParams.get('user_name');
        const confirmationCode = searchParams.get('confirmation_code');

        apiConfirmUser(clientId, username, confirmationCode).then(res => {
            res.json().then(json => {
                if (res.ok) {
                    this.setState({
                        ...this.state,
                        confirmText: "Account successfully confirmed. Log in below:",
                        showLogin: true,
                    });
                }
                else if(res.status === 422) {
                    this.setState({
                        ...this.state,
                        confirmText: "Account already confirmed. Log in below:",
                        showLogin: true,
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        confirmText: `Account confirmation failed. Error: ${json.description}`,
                        showLogin: false,
                    });
                }
            })
        });
    }

    render() {

        

        return (
            <div>
                <p>{this.state.confirmText}</p>
                {this.state.showLogin ? <LoginForm /> : null}
            </div>
        );
    }
}

export default ConfirmUser;
