import React from "react"
import FirstNameField from "../fields/FirstNameField";
import EmailField from "../fields/EmailField";
import PasswordField from "../fields/PasswordField";
import LastNameField from "../fields/LastNameField";
import { apiCreateUser } from "../../api/api";
import ErrorField from "../fields/ErrorField";

class CreateAccountForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            error: '',
        };

        this.handleChangeFirst = this.handleChangeFirst.bind(this);
        this.handleChangeLast = this.handleChangeLast.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
        this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
    }

    handleChangeLast(event) {
        this.setState({
            ...this.state,
            lastName: event.target.value,
        });
    }

    handleChangeEmail(event) {
        this.setState({
            ...this.state,
            email: event.target.value,
        });
    }

    handleChangePassword(event) {
        this.setState({
            ...this.state,
            password: event.target.value,
        });
    }

    handleChangeConfirmPassword(event) {
        this.setState({
            ...this.state,
            confirmPassword: event.target.value,
        });
    }

    handleChangeFirst(event) {
        this.setState({
            ...this.state,
            firstName: event.target.value,
        });
    }

    handleCreateSubmit(event) {
        event.preventDefault();
        // if (this.inputValid()) {
        //     alert(`new account for ${this.state.firstName} ${this.state.lastName}`);
        // } else {
        //     alert('invalid input')
        // }
        if (this.inputValid()) {
            const createResult = apiCreateUser(this.state.email, this.state.firstName + ' ' + this.state.lastName, this.state.password);

            createResult
            .then(res => {
                res.json().then(json => {
                    if (res.ok) {
                        this.setState({
                            ...this.state,
                            error: json.success,
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            error: json.description,
                        });
                    }
                });
            });
        } else {
            this.setState({
                ...this.state,
                error: 'Invalid input',
            });
        }
    }

    inputValid() {
        if (
            this.state.firstName !== '' &&
            this.state.lastName !== '' &&
            this.state.email !== '' &&
            this.state.password !== '' &&
            this.state.confirmPassword !== '' &&
            this.state.password === this.state.confirmPassword
        ) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleCreateSubmit}>
                    <FirstNameField value={this.state.firstName} onChange={this.handleChangeFirst} />
                    <LastNameField value={this.state.lastName} onChange={this.handleChangeLast} />
                    <EmailField value={this.state.email} onChange={this.handleChangeEmail} />
                    <PasswordField value={this.state.password} onChange={this.handleChangePassword} />
                    <PasswordField value={this.state.confirmPassword} onChange={this.handleChangeConfirmPassword} confirm={true} />
                    <ErrorField error={this.state.error} />
                    <input type="submit" value="Submit" />
                </form>
            </div>
        )
    }
}

export default CreateAccountForm;
